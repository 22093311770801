import * as commonUtils from '@dbp-toolkit/common/utils';
import {LanguageSelect} from "@dbp-toolkit/language-select/src/language-select";
import {LoginButton} from "@dbp-toolkit/auth";
import {Translated} from "@dbp-toolkit/common/src/translated";
import {AuthMenuButton} from "@dbp-toolkit/app-shell/src/auth-menu-button";
import {BokuShell} from "./boku-shell";

commonUtils.defineCustomElement('dbp-signature', BokuShell);
commonUtils.defineCustomElement('dbp-language-select', LanguageSelect);
commonUtils.defineCustomElement('dbp-login-button', LoginButton);
commonUtils.defineCustomElement('dbp-auth-menu-button', AuthMenuButton);
commonUtils.defineCustomElement('dbp-translated', Translated);
