import {createInstance} from '@dbp-toolkit/app-shell/src/i18n';
import {css, html, LitElement} from 'lit';
import {ScopedElementsMixin} from '@open-wc/scoped-elements';
import * as commonUtils from '@dbp-toolkit/common/utils';
import * as commonStyles from '@dbp-toolkit/common/styles';
import * as SignatureStyles from './styles';

class BokuShellWelcome extends ScopedElementsMixin(LitElement) {

    constructor() {
        super();
        this._i18n = createInstance();
        this.lang = this._i18n.language;

        this._onVisibilityChanged = this._onVisibilityChanged.bind(this);
    }

    static get properties() {
        return {
            lang: { type: String },
        };
    }

    static set app(app) {
        this._app = app;
    }

    update(changedProperties) {
        changedProperties.forEach((oldValue, propName) => {
            if (propName === "lang") {
                this._i18n.changeLanguage(this.lang);
            }
        });

        super.update(changedProperties);
    }

    static get styles() {
        // language=css
        return css`
            ${commonStyles.getThemeCSS()}
            ${commonStyles.getGeneralCSS()}
            ${SignatureStyles.getSignatureCss()}
            
            .item {
                padding-top: 0.5em;
                padding-bottom: 16px;
                padding-left: 16px;
                margin-left: -16px;
                border-left: 2px solid transparent;
                cursor: pointer;
            }
            
            .item:hover {
                border-left: 2px solid #4dbd38;
                background-color: #efefef;
            }
            
            .item:hover .title-bold {
                color: #4dbd38;
                /*border-bottom: 1px solid #4dbd38;*/
            }
        `;
    }

    _onVisibilityChanged() {
        this.requestUpdate();
    }

    connectedCallback() {
        super.connectedCallback();

        const app = BokuShellWelcome._app;
        app.addEventListener('visibility-changed', this._onVisibilityChanged);
    }

    disconnectedCallback() {
        const app = BokuShellWelcome._app;
        app.removeEventListener('visibility-changed', this._onVisibilityChanged);

        super.disconnectedCallback();
    }

    render() {
        const i18n = this._i18n;
        const app = BokuShellWelcome._app;
        let itemTemplates = [];

        const switchActivity = (e, data) => {
            e.preventDefault();
            app.switchComponent(data.routing_name);
        };

        for (let routeName of app.visibleRoutes) {
            let data = app.metadata[routeName];

            if (routeName !== "welcome") {
                itemTemplates.push(html`
                    <div class="item" @click=${(e) => {switchActivity(e, data);}}>
                        <span class="title-bold">${data.name[this.lang]}</span>
                        <p class="activity-description">${data.description[this.lang]}</p>
                    </div>`);
            }
        }

        return html`
            <p>${i18n.t('welcome.headline', {appname: app.topic.name[this.lang]})}
            ${app.topic.description[this.lang] }</p>
            <br>
            ${itemTemplates}
        `;
    }
}

export const bokuWelcomeMeta = {
    "element": "boku-shell-welcome",
    "module_src": "",
    "routing_name": "welcome",
    "name": {
        "de": "Willkommen",
        "en": "Welcome"
    },
    "short_name": {
        "de": "Willkommen",
        "en": "Welcome"
    },
    "description": {
        "de": "Willkommen",
        "en": "Welcome"
    },
    visible: true,
    required_roles: [],
};

commonUtils.defineCustomElement('boku-shell-welcome', BokuShellWelcome);
